import React, { Component } from 'react'
import {mapper} from '@reduxless/react'
import {size, kebabCase} from 'lodash'
import ContentBoxWide from '../../components/content-wide'
import Content from '../../components/content'
import FormRow from '../../components/form-row'
import Button from '../../components/button'
import firebase from 'firebase/app'
const initState = {
  title: '',
  content: '',
  filename: undefined,
  fileBlob: undefined
}
class PresetAddPage extends Component {
  constructor () {
    super()
    this.state = initState
  }

  /**
   * when user changes file:
   * take the file input and read an
   * blob. update the state with the blob
   *
   * @param {event} ev event object
   */
  handleNewFile (ev) {
    if (size(ev.target.files) > 0) {
      const file = ev.target.files[0]
      const reader = new window.FileReader()
      reader.readAsDataURL(file)
      reader.onload = (e) => {
        // browser completed reading file - display it
        this.setState({
          filename: kebabCase(file.name.split('.')[0]) + '.bwpreset',
          fileBlob: e.target.result
        })
      }
    }
  }
  createNewDbEntry (filename, fullPath, downloadURL) {
    const user = this.props.loggedInUser
    if (user && user.uid) {
      let doc = {}
      doc.title = this.state.title || filename
      doc.content = this.state.content
      doc.fullPath = fullPath
      doc.downloadURL = downloadURL
      doc.userId = user.uid
      doc.creatorName = this.props.loggedInUser.displayName
      doc.creatorMail = this.props.loggedInUser.email
      doc.createdAt = firebase.firestore.FieldValue.serverTimestamp()

      firebase
      .firestore()
      .collection('presets')
      .add(doc)
      .then((docRef) => {
        console.log('Document written with ID: ', docRef)
        // empty reply textarea
        this.setState(initState)
        window.location = '/presets/'
      })
      .catch((error) => {
        console.error('Error adding document: ', error)
      })
    }
  }
  createNewPreset () {
    if (this.state.fileBlob && this.state.filename) {
      const user = this.props.loggedInUser
      const storageRef = firebase.storage().ref()
      const preset = storageRef.child('bwpresets/' + user.displayName + '_' + this.state.filename)
      preset
      .putString(this.state.fileBlob, 'data_url')
      .then((snapshot) => {
        snapshot.ref.getDownloadURL().then((downloadURL) => {
          this.createNewDbEntry(this.state.filename, preset.fullPath, downloadURL)
        })
      }).catch((err) => {
        console.log('Fehler! ', err)
      })
    }
  }
  render () {
    return <div className='PagePresetsAdd'>
      <ContentBoxWide>
        <FormRow>
          <label>Preset Name</label>
          <input
            value={this.state.title}
            onChange={(e) => { this.setState({title: e.target.value}) }}
            type='text' />
        </FormRow>
        <FormRow>
          <label>description</label>
          <textarea
            value={this.state.content}
            onChange={(e) => { this.setState({content: e.target.value}) }} />
        </FormRow>
        <FormRow>
          <label>upload preset</label>
          <input
            onChange={this.handleNewFile.bind(this)}
            type='file' />
        </FormRow>
        <FormRow>
          <label>send</label>
          <Button
            onClick={this.createNewPreset.bind(this)}>
            send
          </Button>
        </FormRow>
      </ContentBoxWide>
    </div>
  }
}

const mapped = mapper(
  {
    // selectors
    loggedInUser: store => store.get('loggedInUser')
  },
  {}
)

export default mapped(PresetAddPage)
