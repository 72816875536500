import React, {Component} from 'react'
import './styles.scss'
import {mapper} from '@reduxless/react'
import Markdown from '../../components/markdown'
import Button from '../../components/button'

class TextArea extends Component {
  constructor () {
    super()
    this.state = {
      content: '',
      showPreview: false,
      showInput: true
    }
  }
  handleSwitchInput () {
    this.setState({
      showInput: !this.state.showInput
    })
  }
  handleSwitchPreview () {
    if (this.state.showPreview === false) {
      this.setState({
        showInput: false,
        showPreview: true
      })
    } else {
      this.setState({
        showInput: true,
        showPreview: false
      })
    }
  }
  render () {
    return <div className='TextAreaPreview'>
      <Button onClick={this.handleSwitchPreview.bind(this)}>Markdown preview</Button>

      <div className='panes'>
        {this.state.showInput &&
          <textarea
            maxLength={this.props.children}
            value={this.props.children}
            onChange={this.props.onChange} />
        }
        {this.state.showPreview && <Markdown>{this.props.children}</Markdown>}
      </div>
    </div>
  }
}
const mapped = mapper(
  {}, {}
)

export default mapped(TextArea)
