import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker'
import { createStore } from '@reduxless/core'
import { Container } from '@reduxless/react'
import config from './.env.js'

window.firebase = require('firebase')
window.firebase.initializeApp(config)

/**
 * Reduxless init
 */
const store = createStore({
  initialState: {
    loggedInUser: {
      uid: '',
      displayName: '',
      email: '',
      photoURL: ''
    },
    currentPost: {
      title: '',
      content: ''
    }
  }
})

/**
 * firebase init
 * subscribe to the login event
 * and set the localStorage for the user
 */
window.firebase.auth().onAuthStateChanged((user) => {
  if (user) {
    // User is signed in.
    store.set('loggedInUser', {
      uid: user.uid || false,
      displayName: user.displayName || (user.email).split('@')[0],
      email: user.email || '',
      photoURL: user.photoURL || ''
    })
  } else {
    // User is signed out.
    console.log('Logged out')
    // store.set('loggedInUser', {})
  }
})

// render the app, yay
ReactDOM.render(<Container store={store}><App /></Container>, document.getElementById('root'))

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister()
