import React, {Component} from 'react'
import './styles.scss'
import {mapper} from '@reduxless/react'
import FormRow from '../../components/form-row'
import Button from '../../components/button'
import TextAreaPreview from '../../components/input-textarea'
import Tags from '../../components/input-tags'
import firebase from 'firebase/app'

/**
 * Create a new topic
 * OR edit a existing one
 */
class PostContent extends Component {
  constructor () {
    super()
    this.state = {
      content: '',
      title: '',
      tags: [],
      suggestions: [
        { id: 1, name: 'music' },
        { id: 2, name: 'tutorial' },
        { id: 3, name: 'video' },
        { id: 4, name: 'audio' },
        { id: 5, name: 'production' },
        { id: 6, name: 'software' },
        { id: 7, name: 'hardware' },
        { id: 8, name: 'bug' },
        { id: 9, name: 'daw' },
        { id: 10, name: 'plugin' },
        { id: 11, name: 'midi' },
        { id: 12, name: 'news' },
        { id: 13, name: 'intern' },
        { id: 14, name: 'mymusic' },
        { id: 15, name: 'modular' },
        { id: 16, name: 'samples' },
        { id: 17, name: 'presets' }
      ]
    }
  }
  componentDidMount () {
    if (this.props.threadId) {
      firebase
      .firestore()
      .collection('threads')
      .doc(this.props.threadId)
      .get()
      .then((doc) => {
        console.log('-> ', doc.data().tags)
        this.setState({
          content: doc.data().content,
          title: doc.data().title,
          tags: doc.data().tags || []
        })
      })
    }
  }

  handleTagsDelete (i) {
    const tags = this.state.tags.slice(0)
    tags.splice(i, 1)
    this.setState({ tags })
  }

  handleTagsAddition (tag) {
    const tags = [].concat(this.state.tags, tag)
    this.setState({ tags })
  }

  handleContentChange (ev) {
    this.setState({content: ev.target.value})
  }

  handleTitleChange (ev) {
    this.setState({title: ev.target.value})
  }

  changeThread (ev) {
    const db = window.firebase.firestore()
    const user = this.props.loggedInUser
    if (user && user.uid && this.state.title) {
      let doc = {}
      doc.title = this.state.title
      doc.content = this.state.content
      doc.userId = user.uid
      doc.creatorName = this.props.loggedInUser.displayName
      doc.lastReplyUserName = this.props.loggedInUser.displayName
      doc.lastReplyDate = window.firebase.firestore.FieldValue.serverTimestamp()
      doc.createdAt = window.firebase.firestore.FieldValue.serverTimestamp()
      doc.tags = this.state.tags
      const collection = db.collection('threads')
      let result = false

      // update or insert?
      if (this.props.threadId) {
        // update
        result = collection.doc(this.props.threadId)
          .set(doc).then((docRef) => {
            console.log('updated Doc ID: ', this.props.threadId)
            window.location = '/topic/' + this.props.threadId
          })
      } else {
        // create new
        result = collection.add(doc).then((docRef) => {
          console.log('New Doc ID: ', docRef)
          window.location = '/topic/' + docRef.id
        })
      }
      result.catch((error) => {
        console.error('Error adding document: ', error)
      })
    } else {
      console.log('Nicht eingeloggt!')
    }
  }
  render () {
    return <div className='PostContent'>
      <FormRow>
        <label>Title</label>
        <input
          maxLength='60'
          type='text'
          value={this.state.title}
          onChange={this.handleTitleChange.bind(this)} />
      </FormRow>
      <FormRow>
        <label>Tags</label>
        <Tags
          tags={this.state.tags}
          suggestions={this.state.suggestions}
          handleTagsAddition={this.handleTagsAddition.bind(this)}
          handleTagsDelete={this.handleTagsDelete.bind(this)}
        />
      </FormRow>

      <FormRow>
        <label>Content</label>
        <TextAreaPreview onChange={this.handleContentChange.bind(this)}>
          {this.state.content}
        </TextAreaPreview>
      </FormRow>

      <FormRow>
        <label>ready to publish?</label>
        <Button
          onClick={this.changeThread.bind(this)}>
          senden
        </Button>
      </FormRow>
    </div>
  }
}
const mapped = mapper(
  {
    // selectors
    loggedInUser: store => store.get('loggedInUser'),
    currentPost: store => store.get('currentPost')
  },
  {
    // actions
    updateCurrentPost: (store, ownProps, data) => store.set('currentPost', data)
  }
)

export default mapped(PostContent)
