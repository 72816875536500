import React, {Component} from 'react'
import ReplyRow from '../../components/reply-row'
import ThreadBig from '../../components/thread-big'
import PostReply from '../../components/post-reply'
import {map} from 'lodash'
import {mapper} from '@reduxless/react'
import firebase from 'firebase/app'

class Thread extends Component {
  constructor ({match}) {
    super()
    this.state = {
      threadId: match.params.id,
      thread: {},
      replies: []
    }
  }
  componentDidMount () {
    firebase.firestore().collection('threads').doc(this.state.threadId).get().then((doc) => {
      this.setState({
        thread: doc.data()
      })
    })

    firebase.firestore()
    .collection('replies')
    .where('threadId', '==', this.state.threadId)
    .orderBy('createdAt', 'asc')
    .onSnapshot((onSnapshot) => {
      let newData = []
      onSnapshot.forEach((doc) => {
        let newDoc = doc.data()
        newDoc.id = doc.id
        newData.push(newDoc)
      })
      this.setState({replies: newData})
    })
  }
  render () {
    return (<div className='PageThread'>
      <ThreadBig id={this.state.threadId} thread={this.state.thread} />
      {map(this.state.replies, (item, index) => {
        return <ReplyRow key={index} reply={item} />
      })}
      <PostReply threadId={this.state.threadId} />
    </div>)
  }
}

const MappedForum = mapper(
  {
    // selectors
    userLoggedIn: store => store.get('userLoggedIn')
  },
  {
    // actions
    updateName: (store, ownProps, status) => store.set('userLoggedIn', status)
  }
)

export default MappedForum(Thread)
