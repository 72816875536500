import React, { Component } from 'react'
import FormRow from '../../components/form-row'
import Button from '../../components/button'
import Content from '../../components/content'
import ContentBoxWide from '../../components/content-wide'
import ContentBoxMiddle from '../../components/content-middle'
import Info from '../../components/info'

class Signin extends Component {
  constructor () {
    super()
    this.state = {
      user: false,
      email: false
    }
    window.firebase.auth().onAuthStateChanged((user) => {
      if (user) {
        this.setState({
          usermail: user.email
        })
      } else {

      }
    })
  }
  createLink (email) {
    const actionCodeSettings = {
      // URL you want to redirect back to. The domain (www.example.com) for this
      // URL must be whitelisted in the Firebase Console.
      url: (process.env.NODE_ENV === 'production') ? 'http://alpha.producer-network.de/validatemail' : 'http://localhost:3000/validatemail',
      // This must be true.
      handleCodeInApp: true,
      iOS: {
        bundleId: 'com.example.ios'
      },
      android: {
        packageName: 'com.example.android',
        installApp: true,
        minimumVersion: '12'
      },
      dynamicLinkDomain: 'pnetwork.page.link'
    }
    window.firebase.auth().sendSignInLinkToEmail(email, actionCodeSettings)
      .then(() => {
        // The link was successfully sent. Inform the user.
        // Save the email locally so you don't need to ask the user for it again
        // if they open the link on the same device.
        window.localStorage.setItem('emailForSignIn', email)
        this.setState({
          inProcess: true,
          info: 'email sent, check your mail and click the link!'
        })
      })
      .catch((error) => {
        // Some error occurred, you can inspect the code: error.code
        this.setState({
          info: 'something went wrong!' + error
        })
      })
  }
  handleChange (ev) {
    this.setState({
      email: ev.target.value
    })
  }
  handleSend (ev) {
    this.createLink(this.state.email)
  }
  render () {
    return (
      <div className='Signin'>
        <ContentBoxWide>
          <Content>
            <h1>Passwordless Login</h1>
            <p>To log in, the user visits a site and enters an ID such as an email address. They are sent a message with a link; they click it and are logged in.</p>
            <p>In other words, the application creates a random, one-time password, and whispers it to the user whenever they need to access. It’s a similar process to resetting your password — which many users do every login anyway!</p>
            {this.state.info &&
              <Info>{this.state.info}</Info>
            }
            <p>{this.state.usermail}</p>
          </Content>
        </ContentBoxWide>

        {!this.state.inProcess &&
          <ContentBoxMiddle>
            <FormRow>
              <label>E-Mail:</label>
              <input type='email' onChange={this.handleChange.bind(this)} />
            </FormRow>
            <FormRow>
              <label />
              <Button onClick={this.handleSend.bind(this)}>Login</Button>
            </FormRow>
          </ContentBoxMiddle>
        }
      </div>
    )
  }
}

export default Signin
