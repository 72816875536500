import React, { Component } from 'react'
import {mapper} from '@reduxless/react'
import {assign} from 'lodash'
import ContentBoxWide from '../../components/content-wide'
import Content from '../../components/content'
import FormRow from '../../components/form-row'
import Button from '../../components/button'

class ProfilePage extends Component {
  constructor () {
    super()
    this.state = {
      info: '',
      error: ''
    }
  }
  handleDisplayNameChange (ev) {
    let newState = assign({}, this.props.loggedInUser, {displayName: ev.target.value})
    this.props.updateUser(newState)
  }
  handlePhotoURLChange (ev) {
    let newState = assign({}, this.props.loggedInUser, {photoURL: ev.target.value})
    this.props.updateUser(newState)
  }
  handleSave (ev) {
    const user = window.firebase.auth().currentUser
    user.updateProfile(this.props.loggedInUser)
    .then(() => {
      this.setState({info: 'Fertig'})
    }).catch((error) => {
      this.setState({error: 'Fehler !!! ' + error})
    })
  }
  render () {
    if (this.props.loggedInUser.email !== '') {
      return <ContentBoxWide>
        <FormRow>
          {this.props.loggedInUser.email && <div>
            <Button onClick={this.props.logout}>
              Logout
            </Button>
          </div>
      }
        </FormRow>
        <FormRow>
          <label>Profile Name: </label>
          <input maxLength='15' type='text' value={this.props.loggedInUser.displayName} onChange={this.handleDisplayNameChange.bind(this)} />
        </FormRow>
        <FormRow>
          <label>Photo URL: </label>
          <input type='text' value={this.props.loggedInUser.photoURL} onChange={this.handlePhotoURLChange.bind(this)} />
        </FormRow>
        <Content>
          {this.state.info && <p className='info'>{this.state.info}</p>}
          {this.state.error && <p className='error'>{this.state.error}</p>}
        </Content>
        <FormRow>
          <Button onClick={this.handleSave.bind(this)}>Save</Button>
        </FormRow>
      </ContentBoxWide>
    } else {
      return <div />
    }
  }
}
const mapped = mapper(
  {
    // selectors
    loggedInUser: store => store.get('loggedInUser')
  },
  {
    // actions
    updateUser: (store, ownProps, newState) => store.set('loggedInUser', newState),
    logout: (store, ownProps, status) => {
      window.firebase.auth().signOut().then(() => {
        // Sign-out successful.
        window.location = '/'
      }).catch((error) => {
        // An error happened.
        console.log('Error', error)
      })
    }
  }
)

export default mapped(ProfilePage)
