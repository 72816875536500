import React from 'react'
import Content from '../../components/content'
import ContentBoxWide from '../../components/content-wide'
import { Link } from 'react-router-dom'

const Home = () => (
  <ContentBoxWide>
    <Content>
      <div className='pnlogo' style={{'height': '60px', 'marginBottom': '40px'}}>producer-network</div>
      <p>The producer network is a music community founded by polarity. Formerly a forum, tutorial and music hub. Lost since 2007 and now back online with new ideas. The website is intended to be a hub for creative people from all areas.</p>
      <p><Link to='/signin'>Sign In</Link> now!</p>
      <h2>More...</h2>
      <ul>
        <li>
          <a href='https://discord.gg/0mh7aOgyrgJc8cQM'>Join The <b>Producer Network</b> Discord Channel</a>
        </li>
        <li>
          <a href='https://discord.gg/0g2ZPafIN3eWParf'>Join The <b>Bitwig</b> Discord Channel</a>
        </li>
      </ul>
    </Content>
  </ContentBoxWide>
)

export default Home
