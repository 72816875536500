import './styles.scss'
import React, { Component } from 'react'
import {mapper} from '@reduxless/react'
import {map, last} from 'lodash'
import ContentBoxWide from '../../components/content-wide'
import Content from '../../components/content'
import FormRow from '../../components/form-row'
import Button from '../../components/button'
import Buttonbar from '../../components/button-bar'
import Spacer from '../../components/spacer'
import firebase from 'firebase/app'
import Markdown from '../../components/markdown'
import dateFormat from '../../modules/date-format'

const initState = {
  itemsOnPage: 100
}

class PresetPage extends Component {
  constructor () {
    super()
    this.state = initState
    this.getThreads()
  }
  getThreads (start) {
    // init db query
    let dbQuery = firebase
    .firestore()
    .collection('presets')
    .orderBy('createdAt', 'desc')

    // we have a start? pagination
    if (start) {
      dbQuery = dbQuery.startAt(start.createdAt)
    }

    // querry nau!!
    dbQuery
    .limit(this.state.itemsOnPage)
    .get()
    .then((onSnapshot) => {
      // our items = threads
      const newData = []
      onSnapshot.forEach((doc) => {
        let newDoc = doc.data()
        newDoc.id = doc.id
        newData.push(newDoc)
      })

      // set state and update dom
      this.setState({
        lastDoc: last(newData),
        presets: newData
      })
    })
  }

  render () {
    return <div className='PagePresets'>
      <Buttonbar>
        <Button link='/presets-add'>Upload a preset</Button>
      </Buttonbar>
      <ContentBoxWide>
        <Content>
          <h1>Preset Party 🎉</h1>
          <Markdown>
            Here you can upload presets and make them available to the community.
            All presets are automatically uploaded to our
            [Github repository](https://github.com/polarity/bitwig-presets).
            This means that anyone who uses the ["Preset Party"](https://github.com/polarity/preset-party-app)
            application, gets your preset loaded directly to the hard disk.
            Just install the ["Preset Party"](https://github.com/polarity/preset-party-app) app and enjoy many,
            many of the community created presets for DAWs or VST plugins. Or Download individual Presets below:
          </Markdown>

          <table className='Table'>
            <thead>
              <tr>
                <th>Created</th>
                <th>Title</th>
                <th>Creator</th>
              </tr>
            </thead>
            <tbody>
              {map(this.state.presets, (preset, index) => {
                return <tr key={preset.id}>
                  <td>{dateFormat(preset.createdAt)}</td>
                  <td><a href={preset.downloadURL}>{preset.title}</a></td>
                  <td>{preset.creatorName}</td>
                </tr>
              })}
            </tbody>
          </table>

        </Content>
      </ContentBoxWide>
    </div>
  }
}

const mapped = mapper(
  {
    // selectors
    loggedInUser: store => store.get('loggedInUser')
  },
  {}
)

export default mapped(PresetPage)
