import React, { Component } from 'react'
import './App.scss'
import { BrowserRouter as Router, Route, Link } from 'react-router-dom'
import Signin from './pages/auth-signin'
import Home from './pages/home'
import Validatemail from './pages/auth-validate-mail'
import Forum from './pages/forum-overview'
import Profile from './pages/profile'
import Thread from './pages/forum-thread'
import NewTopic from './pages/forum-newtopic'
import Presets from './pages/presets'
import PresetsAdd from './pages/presets-add'
import {mapper} from '@reduxless/react'
import Button from './components/button'

/**
 * First top component = the router
 * - add the nav on top
 * - exchange compontents below based on the current routing.
 */
class App extends Component {
  constructor () {
    super()
    this.state = {}
  }

  render () {
    return (
      <Router>
        <div className='App'>
          <div className='PageNavi'>
            <nav>
              <div className='navElement'><Link to='/'>Home</Link></div>
              <div className='navElement'><Link to='/forum/'>Forum</Link></div>
              <div className='navElement'><Link to='/presets/'>Presets</Link></div>
              {this.props.loggedInUser.email &&
                <div className='navElement'>
                  <Link to='/profile/'>Me ({this.props.loggedInUser.displayName})</Link>
                </div>
              }
              {!this.props.loggedInUser.email &&
                <div className='navElement'>
                  <Button link='/signin/'>Sign In!</Button>
                </div>
              }
            </nav>
          </div>

          <div className='PageContent'>
            <Route path='/' exact component={Home} />
            <Route path='/forum/' component={Forum} />
            <Route path='/topic/:id' component={Thread} />
            <Route path='/topic-new/' component={NewTopic} />
            <Route path='/topic-edit/:id' component={NewTopic} />
            <Route path='/profile/' component={Profile} />
            <Route path='/validatemail' component={Validatemail} />
            <Route path='/signin/' component={Signin} />
            <Route path='/presets/' component={Presets} />
            <Route path='/presets-add/' component={PresetsAdd} />
          </div>
        </div>
      </Router>
    )
  }
}
const mapped = mapper(
  {
    // redux selectors
    loggedInUser: store => store.get('loggedInUser')
  },
  {

  }
)

export default mapped(App)
