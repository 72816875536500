import React, {Component} from 'react'
import ThreadSmall from '../../components/thread-small'
import {map, last} from 'lodash'
import {mapper} from '@reduxless/react'
import Buttonbar from '../../components/button-bar'
import Button from '../../components/button'
import Spacer from '../../components/spacer'
import firebase from 'firebase/app'

class Forum extends Component {
  constructor () {
    super()
    this.state = {
      itemsOnPage: 10,
      lastDoc: false,
      threads: []
    }
    this.getThreads()
  }
  getThreads (start) {
    // init db query
    let dbQuery = firebase
    .firestore()
    .collection('threads')
    .orderBy('lastReplyDate', 'desc')

    // we have a start? pagination
    if (start) {
      dbQuery = dbQuery.startAt(start.lastReplyDate)
    }

    // querry nau!!
    dbQuery
    .limit(this.state.itemsOnPage)
    .get()
    .then((onSnapshot) => {
      // our items = threads
      const newData = []
      onSnapshot.forEach((doc) => {
        let newDoc = doc.data()
        newDoc.id = doc.id
        newData.push(newDoc)
      })

      // set state and update dom
      this.setState({
        lastDoc: last(newData),
        threads: newData
      })
    })
  }
  handleFirstPage () {
    this.getThreads()
  }
  handleNextPage () {
    this.getThreads(this.state.lastDoc)
  }
  render () {
    return (<div className='PageForum'>
      <Buttonbar>
        <Button link='/topic-new'>New Topic</Button>
      </Buttonbar>
      {map(this.state.threads, (item, index) => {
        return <ThreadSmall key={index} id={item.id} thread={item} />
      })}
      <Buttonbar>
        <Button onClick={this.handleFirstPage.bind(this)}>start page</Button>
        <Spacer />
        <Button onClick={this.handleNextPage.bind(this)}>next</Button>
      </Buttonbar>
    </div>)
  }
}

const MappedForum = mapper(
  {}, {}
)

export default MappedForum(Forum)
