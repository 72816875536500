import React, {Component} from 'react'
import './styles.scss'
import {mapper} from '@reduxless/react'
import FormRow from '../../components/form-row'
import Button from '../../components/button'
import ContentBoxWide from '../../components/content-wide'
import firebase from 'firebase/app'
import TextAreaPreview from '../../components/input-textarea'

class PostReply extends Component {
  constructor () {
    super()
    this.state = {
      content: ''
    }
  }

  /**
   * called everytime the user changes the
   * texarea in the dom
   * @param {event} ev
   */
  handleContentChange (ev) {
    this.setState({content: ev.target.value})
  }

  /**
   * user clicked on "send reply"
   * use the content from the local state
   * and send it to firebase.
   * @param {event} ev
   */
  postReply (ev) {
    const user = this.props.loggedInUser
    if (user && user.uid) {
      let doc = {}
      doc.content = this.state.content
      doc.userId = user.uid
      doc.threadId = this.props.threadId
      doc.creatorName = this.props.loggedInUser.displayName
      doc.createdAt = firebase.firestore.FieldValue.serverTimestamp()

      firebase
      .firestore()
      .collection('replies')
      .add(doc)
      .then((docRef) => {
        console.log('Document written with ID: ', docRef)
        // empty reply textarea
        this.setState({content: ''})
      })
      .catch((error) => {
        console.error('Error adding document: ', error)
      })

      // update main thread reply Date
      firebase
      .firestore()
      .collection('threads')
      .doc(this.props.threadId)
      .set({
        lastReplyDate: firebase.firestore.FieldValue.serverTimestamp(),
        lastReplyUserName: this.props.loggedInUser.displayName
      }, { merge: true })
      .then((docRef) => {
        console.log('updated thread, yes')
      })
      .catch((error) => {
        console.error('Error updating thread: ', error)
      })
    } else {
      console.log('Nicht eingeloggt!')
    }
  }
  render () {
    return <div className='PostContent'>
      <ContentBoxWide>
        <FormRow>
          <label>Content</label>
          <TextAreaPreview onChange={this.handleContentChange.bind(this)}>{this.state.content}</TextAreaPreview>
        </FormRow>
        <FormRow>
          <label>post reply</label>
          <Button
            onClick={this.postReply.bind(this)}>
            reply
          </Button>
        </FormRow>
      </ContentBoxWide>
    </div>
  }
}
const mapped = mapper(
  {
    // selectors
    loggedInUser: store => store.get('loggedInUser')
  }, {}
)

export default mapped(PostReply)
