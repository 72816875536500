import React from 'react'
import './styles.scss'

/**
 * Content Wrapper
 * brings in some margings and styles
 * for the content
 */
export default (props) => {
  return <div className='Content'>
    {props.children}
  </div>
}
