import React, {Component} from 'react'
import PostContent from '../../components/post-content'
import ContentWide from '../../components/content-wide'
import Content from '../../components/content'

class Forum extends Component {
  constructor ({match}) {
    super()
    this.state = {
      threadId: match.params.id
    }
  }
  render () {
    return (<div className='PageForumNewTopic'>
      <ContentWide>
        <Content>
          <h1>Your Topic</h1>
          <p>Add a title and your text and hopefully you end up with a lot of answers!
            You can use <a rel='noopener noreferrer' target='_blank' href='https://commonmark.org/help/'>markdown</a> for styling.
          </p>
        </Content>
        <PostContent threadId={this.state.threadId} />
      </ContentWide>
    </div>)
  }
}
export default Forum
