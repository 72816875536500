import React, {Component} from 'react'
import './styles.scss'
import dateFormat from '../../modules/date-format'
import Content from '../../components/content'
import Markdown from '../../components/markdown'
import Button from '../../components/button'
import {mapper} from '@reduxless/react'

class ThreadBig extends Component {
  constructor () {
    super()
    this.state = {
      delete: false
    }
  }
  handleDeleteThread () {
    this.setState({
      delete: true
    })
  }
  handleDelete () {
    const db = window.firebase.firestore()
    db.collection('threads').doc(this.props.id).delete()
    .then(() => {
      console.log('thread deleted')
      window.location = '/forum'
    }).catch((err) => {
      console.log('Was looss', err)
      this.setState({err: err})
    })
  }

  render () {
    return <div className='ThreadBig'>
      <div className='Title'>
        <span className='headline'>{this.props.thread.title}</span>
        <span className='info'>by {this.props.thread.creatorName}
          {this.props.thread.createdAt &&
            <span className='timestamp'> » {dateFormat(this.props.thread.createdAt)} </span>
          }
        </span>
        <span className='actions'>
          {!this.state.delete && this.props.loggedInUser && this.props.loggedInUser.uid === this.props.thread.userId &&
            <Button onClick={this.handleDeleteThread.bind(this)}>X</Button>
          }
          {this.state.delete &&
            <Button onClick={this.handleDelete.bind(this)}>Yes! delete this thread!</Button>
          }
          {this.props.loggedInUser && this.props.loggedInUser.uid === this.props.thread.userId &&
            <Button link={`/topic-edit/${this.props.id}`}>Edit</Button>
          }
        </span>

      </div>
      <Content>
        <Markdown>
          {this.props.thread.content || ''}
        </Markdown>
      </Content>
    </div>
  }
}
const mapped = mapper(
  {
    // selectors
    loggedInUser: store => store.get('loggedInUser')
  },
  {
  }
)

export default mapped(ThreadBig)
