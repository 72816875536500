import React, {Component} from 'react'
import './styles.scss'
import {map} from 'lodash'
import { Link } from 'react-router-dom'
import dateFormat from '../../modules/date-format'

export default class ThreadSmall extends Component {
  render () {
    return <div className='ThreadSmall'>
      <div className='Title'>
        <span className='headline'>
          <Link to={`/topic/${this.props.id}`}>{this.props.thread.title}</Link>
        </span>
        <span className='subheader'>by {this.props.thread.creatorName}
          {this.props.thread.createdAt &&
            <span className='timestamp'> » {dateFormat(this.props.thread.createdAt)} </span>
          }
        </span>
        <div className='tags'>
          {map(this.props.thread.tags, (tag) => {
            return <span key={tag.id}>{tag.name}</span>
          })}
        </div>
      </div>
      <div className='Replies'>{this.props.thread.countReply}</div>
      <div className='Views'>{this.props.thread.countViews}</div>
      <div className='LastReply'>
        by {this.props.thread.lastReplyUserName}
        {this.props.thread.lastReplyDate &&
          <span> » {dateFormat(this.props.thread.lastReplyDate)}</span>
        }
      </div>
    </div>
  }
}
