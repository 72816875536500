import React, {Component} from 'react'
import MDX from '@mdx-js/runtime'
import Player from '../../components/player'

import './styles.scss'

const scope = {
  Button: () => <h1 />,
  Player: Player
}
export default class Markdown extends Component {
  render () {
    return <div className='Markdown'>
      <MDX scope={scope} children={this.props.children} />
    </div>
  }
}
