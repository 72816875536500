import React, {Component} from 'react'
import './styles.scss'
import Content from '../../components/content'
import dateFormat from '../../modules/date-format'
import {mapper} from '@reduxless/react'
import Button from '../../components/button'
import Markdown from '../../components/markdown'

class ReplyRow extends Component {
  constructor () {
    super()
    this.state = {
      delete: false
    }
  }
  handleDeleteReply () {
    this.setState({
      delete: true
    })
  }
  handleDelete () {
    const db = window.firebase.firestore()
    db.collection('replies').doc(this.props.reply.id).delete()
    .then(() => {
      console.log('reply deleted')
    }).catch((err) => {
      this.setState({err: err})
    })
  }
  render () {
    return <div className='ReplyRow'>
      <div className='Title'>
        <span className='info'>
          reply by {this.props.reply.creatorName}
          {this.props.reply.createdAt &&
            <span className='timestamp'> » {dateFormat(this.props.reply.createdAt)} </span>
          }
        </span>
        <span className='actions'>
          {!this.state.delete && this.props.loggedInUser && this.props.loggedInUser.uid === this.props.reply.userId &&
            <span onClick={this.handleDeleteReply.bind(this)}>X</span>
          }
          {this.state.delete &&
            <Button onClick={this.handleDelete.bind(this)}>Yes! delete this reply!</Button>
          }
        </span>
      </div>
      <Content>
        <Markdown>
          {this.props.reply.content}
        </Markdown>
      </Content>
    </div>
  }
}
const mapped = mapper(
  {
    // selectors
    loggedInUser: store => store.get('loggedInUser')
  },
  {
  }
)

export default mapped(ReplyRow)
