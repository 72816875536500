import React from 'react'
import './styles.scss'
import { Link } from 'react-router-dom'

/**
 * Simple Button to use on the site
 */
export default (props) => {
  if (props.link) {
    return <Link to={props.link}>
      <button className='Button'>{props.children}</button>
    </Link>
  } else {
    return <button className='Button' onClick={props.onClick}>{props.children}</button>
  }
}
