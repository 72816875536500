import React, {Component} from 'react'
import ReactPlayer from 'react-player'
import './styles.scss'

export default class Markdown extends Component {
  render () {
    return <ReactPlayer
      width='100%'
      className='ReactPlayer'
      url={this.props.children} />
  }
}
