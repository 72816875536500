import React, {Component} from 'react'
import './styles.scss'
import ReactTags from 'react-tag-autocomplete'

/**
 */
class InputTags extends Component {
  render () {
    return <div className='InputTags'>
      <ReactTags
        inputAttributes={{maxLength: 10}}
        tags={this.props.tags}
        suggestions={this.props.suggestions}
        handleDelete={this.props.handleTagsDelete.bind(this)}
        handleAddition={this.props.handleTagsAddition.bind(this)} />
    </div>
  }
}

export default InputTags
