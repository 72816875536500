import React from 'react'
import './styles.scss'

export default (props) => {
  return (<div className='ContentBox-Wide'>
    <div className='Wrapper'>
      {props.children}
    </div>
  </div>)
}
